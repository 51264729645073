<template>
    <section>
        <modal ref="modalSolicitudCambioPrecio" :titulo="title()" :icon="icons()" tamano="modal-lg" no-aceptar no-cancelar :btns="botones" @accion="ejecutar">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col px-0">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <img :src="producto.imagen" class="obj-cover border br-12" width="150" height="150" />
                        </div>
                        <div class="col d-flex flex-column justify-content-between">
                            <p class="text-general f-15 f-600" mb-2>
                                {{ producto.nombre }}
                            </p>
                            <template v-if="sugerencia.activa">
                                <div class="row mx-0 my-1">
                                    <p class="col-auto px-0 text-general f-14 f-600">
                                        {{ producto.precio_sugerido }}
                                    </p>
                                    <p class="col-auto text-gris descuento f-600 f-14">
                                        {{ producto.precio }}
                                    </p>
                                </div>
                                <div class="row mx-0 my-1 align-items-center">
                                    <div class="bg-general3 text-white px-3 br-12 f-12">
                                        {{ sugerencia.porcentaje }} ({{ sugerencia.estado == 1 ? 'Cambio de precio pendiente' : sugerencia.estado == 2 ? 'Aprobado' : sugerencia.estado == 3 ? 'Aprobado' : 'Rechazado' }})
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row mx-0 my-1">
                                    <p class="col-auto px-0 text-general f-14">
                                        {{ promocion.activa ? promocion.valor : producto.precio }}
                                    </p>
                                    <p v-show="promocion.activa" class="col-auto text-gris descuento f-600 f-14">
                                        {{ producto.precio }}
                                    </p>
                                </div>
                                <div v-show="promocion.activa" class="row mx-0 align-items-center">
                                    <div class="bg-general text-white px-3 br-12 f-12">
                                        {{ promocion.texto }}
                                    </div>
                                </div>
                            </template>
                            <p class="text-general  f-14 my-1">
                                <b class="f-500">Presentacion: </b> {{ producto.presentacion }}
                            </p>
                            <p class="text-general  f-14 my-1">
                                <b class="f-500"> Unidad de Medida: </b> {{ producto.unidad }}
                            </p>
                            <p class="text-general  f-14 my-1">
                                <b class="f-500">Venta mínima:</b> {{ producto.cantidad_minima }} {{ producto.unidad }}
                            </p>
                            <div class="row mx-0 my-2 justify-content-between">
                                <p class="text-general col-auto px-0 f-14">
                                    <b> Cantidad Pedida:</b> {{ producto.cantidad_pedida }}
                                </p>
                                <p class="text-general col-auto f-14">
                                    <b> Valor Total:</b> {{ producto.total_pedida }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Datos pedido -->
                    <div class="row mx-0 mt-3 align-items-center">
                        <div class="col px-0 text-center">
                            <i class="icon-receipt text-gris f-25" />
                            <b class="text-general f-500">
                                Pedido:
                            </b>
                            {{ id_pedido }}
                        </div>
                        <div class="col px-0  text-center">
                            <b class="text-general f-500">
                                Valor Pedido:
                            </b>
                            {{ convertMoney(resumen.val_total, idm_moneda) }}
                        </div>
                        <div class="col px-0 text-center">
                            <b class="text-general f-500">
                                Fecha:
                            </b>
                            {{ pedido.created_at | helper-fecha('DD MMM YYYY') }}
                        </div>
                    </div>
                    <!-- Info leechero -->
                    <div class="row mx-0 justify-center my-2">
                        <div class="col-auto">
                            <img :src="lechero.logo_mini" class="obj-cover rounded-circle border" width="50" height="50" />
                        </div>
                        <div class="col-5 px-0">
                            <p class="tres-puntos">
                                <b class="text-general f-500">Vendedor:</b>
                                {{ lechero.nombre }}
                            </p>
                            <p>
                                <b class="text-general f-500">
                                    Cedis:
                                </b>
                                {{ cedis_lechero }}
                            </p>
                        </div>
                        <div class="col-5">
                            <p class="tres-puntos text-right">
                                <b class="text-general f-500">Motivo:</b>
                                {{ sugerencia.motivo_solicitud }}
                            </p>
                            <div class="row mx-0 align-items-center justify-content-end">
                                <div
                                :class="`br-50 ${sugerencia.estado == 1 ? 'bg-orange' : sugerencia.estado == 2 ? 'bg-green' : sugerencia.estado == 3 ? 'bg-green' : 'bg-orange'}`"
                                style="width:15px;height:15px;"
                                />
                                <p class="col-auto pr-0 pl-2">
                                    <b class="text-general f-500">
                                        Estado:
                                    </b>
                                    {{ sugerencia.estado == 1 ? 'pendiente' : sugerencia.estado == 2 ? 'Aprobado' : sugerencia.estado == 3 ? 'Aprobado' : 'Rechazado' }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- Listado -->
                    <div v-show="sugerencia.estado == 2 || sugerencia.estado == 3 || sugerencia.estado == 4" class="row mx-0 justify-center my-4">
                        <div class="col pl-4">
                            <div class="d-middle mb-2">
                                <div class="col-auto px-2 pr-3">
                                    <img :src="sugerencia.foto_admin" class="obj-cover rounded-circle border" width="50" height="50" />
                                </div>
                                <div class="col px-0">
                                    <p class="tres-puntos">
                                        <b class="text-general f-500">Admin:</b>
                                        {{ sugerencia.nombre_admin }}
                                    </p>
                                    <p v-show="sugerencia.estado == 1 || sugerencia.estado == 2 || sugerencia.estado == 3">
                                        <b class="text-general f-500"> Motivo </b>
                                        {{ sugerencia.motivo_solicitud }}
                                    </p>
                                    <p v-show="sugerencia.estado == 4">
                                        <b class="text-general f-500"> Motivo </b>
                                        {{ sugerencia.motivo_rechazo }}
                                    </p>
                                </div>
                            </div>
                            <p v-show="sugerencia.justificacion" class="f-14 text-general p-2 border br-10">
                                {{ sugerencia.justificacion }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </modal>
        <modal-rechazar-cambio-precio ref="modalRechazarCambio" :id-sugerencia="id_sugerencia" @updateTable="$emit('update')" />
        <modal-aprobar-cambio-precio ref="modalAprobarCambioPrecio" :sugerencia="data_sugerencia" @updateTable="$emit('update')" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalRechazarCambioPrecio: () => import('./modalRechazarCambioPrecio'),
        modalAprobarCambioPrecio: () => import('./modalAprobarCambioPrecio'),
    },
    data(){
        return {
            producto:{},
            data_sugerencia:{},
            sugerencia:{
                activa:false,
                estado:0,
                unidad_sugerida:0,
                porcentaje:'',
                motivo_solicitud:'',
                motivo_rechazo:'',
                nombre_admin:'',
                foto_admin:'',
                justificacion:''
            },
            promocion:{
                activa:false,
                texto:'',
                valor:0
            },
            id_sugerencia:0
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
            resumen: 'pedidos/pedidos_admin/resumen',
            lechero: 'pedidos/pedidos_admin/cliente',
            productos: 'pedidos/pedidos_admin/productos'
        }),
        idm_moneda(){
            return this.pedido.idm_moneda
        },
        cedis_lechero(){
            if(this.pedido){
                let cedi = this.cedis_calculo.find(o=>o.id === this.pedido.id_cedis)

                return cedi ? cedi.nombre : ""

            }else{
                return ""
            }
        },
        botones(){
            let btns = [
                {
                    texto: 'Cerrar',
                    color: 'bg-light-f5 text-general2 br-12 px-3',
                    accion: 'cerrar'
                }
            ]
            if(this.sugerencia.estado == 1 && this.$can('botones_gestion_admin_cambio_precio_gestionar')){
                btns.push(
                    {
                        texto: 'Rechazar',
                        color: 'bg-general2 text-white br-12 px-3',
                        accion: 'rechazar'
                    },
                    {
                        texto: 'Aprobar',
                        color: 'bg-general text-white br-12 px-3',
                        accion: 'aprobar'
                    }
                )
            }
            return btns

        }

    },
    methods: {
        toggle(){
            this.$refs.modalSolicitudCambioPrecio.toggle();
        },
        async openSolicitud(row){
            this.limpiar()
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', row.id_pedido)
            await this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(row.id_pedido))

            const registro = this.productos.find(o=>o.id === row.id_admin_pedido_producto)

            this.cargar_datos(registro)

            this.id_sugerencia = row.id
            await this.datos_sugerencia(row.id)
            this.$refs.modalSolicitudCambioPrecio.toggle()
        },
        cargar_datos(prod){

            let decimalesFrancion = 0
            if(prod.producto.cantidad_tipo === 1){
                decimalesFrancion = 0
            } else{
                decimalesFrancion = 2
            }

            this.producto = {
                id:prod.producto.id,
                id_pedido_producto:prod.id,
                nombre:prod.producto.nombre,
                detalle_desconocido:prod.producto.detalle_desconocido,
                descripcion:prod.producto.descripcion,
                imagen:prod.producto.imagen,
                precio: this.convertMoney(prod.unidad_teorica,this.idm_moneda),
                precio_final: prod.unidad_final,
                presentacion:prod.producto.presentacion,
                unidad:prod.producto.unidad,
                cantidad_minima:this.agregarSeparadoresNumero(prod.producto.cantidad_minima,decimalesFrancion),
                cantidad_pedida: this.agregarSeparadoresNumero(prod.cantidad,decimalesFrancion) + prod.producto.unidad,
                total_pedida: this.convertMoney(prod.total_final,this.idm_moneda)

            }

            if(prod.sugerido){
                this.producto.precio_sugerido =  this.convertMoney(prod.sugerido.unidad_sugerida,this.idm_moneda)
                this.sugerencia.activa = true
                this.sugerencia.id = prod.sugerido.id
                this.sugerencia.estado = prod.sugerido.estado
                this.sugerencia.unidad_sugerida =  this.convertMoney(prod.sugerido.unidad_sugerida,this.idm_moneda)
                this.sugerencia.porcentaje = prod.sugerido.porcentaje
            }

            if(prod.promocion){
                this.promocion.activa = true
                this.promocion.texto = prod.data_promocion.texto
                this.promocion.valor =  this.convertMoney(prod.unidad_final,this.idm_moneda)
            }
        },
        async datos_sugerencia(id){
            const {data} = await Pedidos.datos_sugerencia(id)

            this.data_sugerencia = data.sugerencia
            this.sugerencia.motivo_solicitud = data.sugerencia.motivo_solicitud
            this.sugerencia.motivo_rechazo = data.sugerencia.motivo_rechazo
            this.sugerencia.nombre_admin = data.sugerencia.nombre_admin
            this.sugerencia.foto_admin = data.sugerencia.foto_admin
            this.sugerencia.justificacion = data.sugerencia.justificacion
            this.sugerencia.justificacion = data.sugerencia.justificacion
        },
        ejecutar(accion){
            if(accion == 'rechazar'){
                this.toggle();
                this.$refs.modalRechazarCambio.toggle()
            }else if( accion == 'aprobar'){
                this.toggle();
                this.$refs.modalAprobarCambioPrecio.toggle()
            }else {
                this.toggle()
            }
        },
        limpiar(){

            this.producto = {
                id:0,
                nombre:'',
                descripcion:'',
                comentario:'',
                imagen:'',
                precio: 0,
                precio_final: 0,
                presentacion:'',
                unidad:'',
                cantidad_minima:0,
                cantidad_pedida: 0,
                total_pedida: 0
            }

            this.sugerencia={
                activa:false,
                estado:0,
                unidad_sugerida:0,
                porcentaje:''
            }

            this.promocion={
                activa:false,
                texto:'',
                valor:0
            }
        },
        title(){
            return this.sugerencia.estado == 1 ? 'Solicitud cambio precio' : this.sugerencia.estado == 2 ? 'Cambio de precio Aprobado' : this.sugerencia.estado == 3 ? 'Cambio de precio Aprobado' : 'Cambio de precio rechazado'
        },
        icons(){
            return this.sugerencia.estado == 1 ? 'pendiente' : this.sugerencia.estado == 2 ? 'aprobado' : this.sugerencia.estado == 3 ? 'aprobado' : 'rechazado'
        }
    }
}
</script>
<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.bg-green{
    background-color: #28D07B;
}
</style>
